<template>
	<div>
		培训中心
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>
<style lang="scss" scoped>

</style>
